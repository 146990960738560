@font-face {
  font-family: "SFPro";
  src: local("SF_Pro_Regular.OTF"), url("/css/fonts/SF_Pro_Regular.woff") format("woff"), url("/css/fonts/SF_Pro_Regular.woff2") format("woff2"), url("/css/fonts/SF_Pro_Regular.ttf") format("ttf");
  font-display: swap;
}
@font-face {
  font-family: "SFProRounded";
  src: local("SF_Pro_Rounded_Regular.OTF"), url("/css/fonts/SF_Pro_Rounded_Regular.woff") format("woff"), url("/css/fonts/SF_Pro_Rounded_Regular.woff2") format("woff2"), url("/css/fonts/SF_Pro_Rounded_Regular.ttf") format("ttf");
  font-display: swap;
}
.SFPro {
  font-family: "SFPro", "Roboto", "-apple-system", sans-serif, Arial;
}

.SFProRounded {
  font-family: "SFProRounded", "Roboto", "-apple-system", sans-serif, Arial;
}

.text-40-b {
  font-size: 2.5rem !important;
  line-height: 3.75rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-40-b {
  font-size: 2.875rem !important;
  line-height: 5rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-32-b {
  font-size: 2rem !important;
  line-height: 3rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-32-b {
  font-size: 2.625rem !important;
  line-height: 4.0625rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-32 {
  font-size: 2rem !important;
  line-height: 3rem !important;
  letter-spacing: 0;
  font-weight: 400;
}
body.responsive-device .text-32 {
  font-size: 2.5rem !important;
  line-height: 3.75rem !important;
  letter-spacing: 0;
  font-weight: 400;
}

.text-28-b {
  font-size: 1.75rem !important;
  line-height: 2.625rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-28-b {
  font-size: 2.375rem !important;
  line-height: 3.75rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-28-b-3 {
  font-size: 1.75rem !important;
  line-height: 2.25rem !important;
  letter-spacing: -3;
  font-weight: 700;
}
body.responsive-device .text-28-b-3 {
  font-size: 2.375rem !important;
  line-height: 3.75rem !important;
  letter-spacing: -3;
  font-weight: 700;
}

.text-26-b-3 {
  font-size: 1.625rem !important;
  line-height: 2.4375rem !important;
  letter-spacing: -3;
  font-weight: 700;
}
body.responsive-device .text-26-b-3 {
  font-size: 2.25rem !important;
  line-height: 3.625rem !important;
  letter-spacing: -3;
  font-weight: 700;
}

.text-26-b {
  font-size: 1.625rem !important;
  line-height: 2.4375rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-26-b {
  font-size: 2.25rem !important;
  line-height: 3.625rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-24-b-3 {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: -3;
  font-weight: 700;
}
body.responsive-device .text-24-b-3 {
  font-size: 2.1875rem !important;
  line-height: 3.375rem !important;
  letter-spacing: -3;
  font-weight: 700;
}

.text-24-b {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-24-b {
  font-size: 2.1875rem !important;
  line-height: 3.375rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-24-3 {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: -3;
  font-weight: 400;
}
body.responsive-device .text-24-3 {
  font-size: 2.1875rem !important;
  line-height: 3.375rem !important;
  letter-spacing: -3;
  font-weight: 400;
}

.text-24 {
  font-size: 1.5rem !important;
  line-height: 2.25rem !important;
  letter-spacing: 0;
  font-weight: 400;
}
body.responsive-device .text-24 {
  font-size: 2.1875rem !important;
  line-height: 3.375rem !important;
  letter-spacing: 0;
  font-weight: 400;
}

.text-22-b {
  font-size: 1.375rem !important;
  line-height: 2.0625rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-22-b {
  font-size: 2.0625rem !important;
  line-height: 3.0625rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-22 {
  font-size: 1.375rem !important;
  line-height: 2.0625rem !important;
  letter-spacing: 0;
  font-weight: 400;
}
body.responsive-device .text-22 {
  font-size: 2.0625rem !important;
  line-height: 3.0625rem !important;
  letter-spacing: 0;
  font-weight: 400;
}

.text-20-b-3 {
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
  letter-spacing: -3;
  font-weight: 700;
}
body.responsive-device .text-20-b-3 {
  font-size: 1.875rem !important;
  line-height: 2.875rem !important;
  letter-spacing: -3;
  font-weight: 700;
}

.text-20-b {
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-20-b {
  font-size: 1.875rem !important;
  line-height: 2.875rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-20-3 {
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -3;
  font-weight: 400;
}
body.responsive-device .text-20-3 {
  font-size: 1.875rem !important;
  line-height: 2.875rem !important;
  letter-spacing: -3;
  font-weight: 400;
}

.text-20 {
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
  letter-spacing: 0;
  font-weight: 400;
}
body.responsive-device .text-20 {
  font-size: 1.875rem !important;
  line-height: 2.875rem !important;
  letter-spacing: 0;
  font-weight: 400;
}

.text-18-b {
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-18-b {
  font-size: 1.8125rem !important;
  line-height: 3rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-18-b-3 {
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: -3;
  font-weight: 700;
}
body.responsive-device .text-18-b-3 {
  font-size: 1.8125rem !important;
  line-height: 3rem !important;
  letter-spacing: -3;
  font-weight: 700;
}

.text-18 {
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0;
  font-weight: 400;
}
body.responsive-device .text-18 {
  font-size: 1.8125rem !important;
  line-height: 3rem !important;
  letter-spacing: 0;
  font-weight: 400;
}

.text-18-3 {
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: -3;
  font-weight: 400;
}
body.responsive-device .text-18-3 {
  font-size: 1.8125rem !important;
  line-height: 3rem !important;
  letter-spacing: -3;
  font-weight: 400;
}

.text-16-b {
  font-size: 1rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-16-b {
  font-size: 1.625rem !important;
  line-height: 2.8125rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-16-3 {
  font-size: 1rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: -3;
  font-weight: 400;
}
body.responsive-device .text-16-3 {
  font-size: 1.625rem !important;
  line-height: 2.8125rem !important;
  letter-spacing: -3;
  font-weight: 400;
}

.text-16 {
  font-size: 1rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0;
  font-weight: 400;
}
body.responsive-device .text-16 {
  font-size: 1.625rem !important;
  line-height: 2.8125rem !important;
  letter-spacing: 0;
  font-weight: 400;
}

.text-14-b {
  font-size: 0.875rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0;
  font-weight: 700;
}
body.responsive-device .text-14-b {
  font-size: 1.4375rem !important;
  line-height: 2.6875rem !important;
  letter-spacing: 0;
  font-weight: 700;
}

.text-14 {
  font-size: 0.875rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0;
  font-weight: 400;
}
body.responsive-device .text-14 {
  font-size: 1.4375rem !important;
  line-height: 2.6875rem !important;
  letter-spacing: 0;
  font-weight: 400;
}

.text-14-3 {
  font-size: 0.875rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: -3;
  font-weight: 400;
}
body.responsive-device .text-14-3 {
  font-size: 1.4375rem !important;
  line-height: 2.6875rem !important;
  letter-spacing: -3;
  font-weight: 400;
}

.text-12 {
  font-size: 0.75rem !important;
  line-height: 1.5rem !important;
  letter-spacing: 0;
  font-weight: 400;
}
body.responsive-device .text-12 {
  font-size: 1.375rem !important;
  line-height: 2.5rem !important;
  letter-spacing: 0;
  font-weight: 400;
}

.text-tertiary {
  color: #e8f0f8;
}

.text-quaternary {
  color: #999999;
}

.text-quinary {
  color: #ececec;
}

.text-senary {
  color: #666666;
}

.text-excellent {
  color: #1a96ff;
}

.text-great {
  color: #10b972;
}

.text-good {
  color: #ffc824;
}

.text-normal {
  color: #f24040;
}

.text-Memory {
  color: #10b972;
}

.text-ExecutiveFunction {
  color: #a660dc;
}

.text-Calculation {
  color: #1996ff;
}

.text-Language {
  color: #10afb9;
}

.text-Attention {
  color: #ff7a00;
}

.text-VisuoSpatial {
  color: #ff6bcd;
}

.text-a-primary {
  color: #2c4367;
}

.text-a-secondary {
  color: #6e859d;
}

.text-a-tertiary {
  color: #799cab;
}

.text-a-quaternary {
  color: #0089ff;
}

.bg-tertiary {
  background-color: #e8f0f8;
}

.bg-quaternary {
  background-color: #999999;
}

.bg-quinary {
  background-color: #ececec;
}

.bg-senary {
  background-color: #666666;
}

.bg-excellent {
  background-color: #1a96ff;
}

.bg-great {
  background-color: #10b972;
}

.bg-good {
  background-color: #ffc824;
}

.bg-normal {
  background-color: #f24040;
}

.bg-Memory {
  background-color: rgba(16, 185, 114, 0.1);
}

.bg-ExecutiveFunction {
  background-color: rgba(166, 96, 220, 0.1);
}

.bg-Calculation {
  background-color: rgba(25, 150, 255, 0.1);
}

.bg-Language {
  background-color: rgba(16, 175, 185, 0.1);
}

.bg-Attention {
  background-color: rgba(255, 122, 0, 0.1);
}

.bg-VisuoSpatial {
  background-color: rgba(255, 107, 205, 0.1);
}

.bg-a-primary {
  background-color: #2c4367;
}

.bg-a-secondary {
  background-color: #6e859d;
}

.bg-a-tertiary {
  background-color: #799cab;
}

.bg-a-quaternary {
  background-color: #0089ff;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

#q-app,
.q-dialog {
  font-family: "SFPro", "Roboto", "-apple-system", sans-serif, Arial;
  white-space: pre-line !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#q-app .max-width,
.q-dialog .max-width {
  max-width: 64rem;
}
body.extra-display #q-app .max-width,
body.extra-display .q-dialog .max-width {
  min-width: 37.5rem;
  max-width: calc(var(--vh, 1vh) * 60);
}

#q-app .default-view-layout,
.q-dialog .default-view-layout {
  padding: 1.5rem 1.25rem 2.5rem;
}
body.responsive-device #q-app .default-view-layout,
body.responsive-device .q-dialog .default-view-layout {
  padding: 1.5rem 2.5rem 2.5rem;
}

#q-app .responsive-full-height,
.q-dialog .responsive-full-height {
  min-height: calc(var(--vh, 1vh) * 100);
}
#q-app .responsive-at-height,
.q-dialog .responsive-at-height {
  min-height: calc(var(--vh, 1vh) * 100 - 6.75rem);
}
#q-app .main-content-container,
.q-dialog .main-content-container {
  width: 100%;
}
#q-app .main-content-container .message-layout,
.q-dialog .main-content-container .message-layout {
  margin-bottom: 0.5rem;
  border-radius: 1.25rem;
  background-color: white;
  border: 1px solid #ececec;
}
body.responsive-device #q-app .main-content-container .message-layout,
body.responsive-device .q-dialog .main-content-container .message-layout {
  margin-bottom: 1rem;
}

#q-app .main-content-container .message-layout.question,
.q-dialog .main-content-container .message-layout.question {
  padding: 0.625rem 1rem;
}
body.responsive-device #q-app .main-content-container .message-layout.question,
body.responsive-device .q-dialog .main-content-container .message-layout.question {
  padding: 1.125rem 1.875rem;
}

#q-app .main-content-container .message-layout.question p,
.q-dialog .main-content-container .message-layout.question p {
  margin: 0;
}

.q-dialog.vertical-dialog .q-card {
  width: 90vw;
  max-width: 25rem;
}
body.responsive-device .q-dialog.vertical-dialog .q-card {
  width: 87.5vw;
  max-width: 43.75rem;
}

body.extra-display .q-dialog.vertical-dialog .q-card {
  width: calc(var(--vh, 1vh) * 54);
  max-width: 33.75rem;
}

.q-dialog.horizontal-dialog .q-card {
  width: 80vw;
  max-width: 37rem;
}
body.responsive-device .q-dialog.horizontal-dialog .q-card {
  width: 85vw;
  max-width: 68.75rem;
}

body.extra-display .q-dialog.horizontal-dialog .q-card {
  width: 80vw;
  max-width: 60rem;
}

.chat-text-box {
  width: 100%;
  padding: 0.625rem 1rem;
  border-radius: 1.25rem;
  box-shadow: 0 0 0 1px #ececec;
  outline: none;
  -webkit-appearance: none;
  background: #fff;
  margin-bottom: 0.5rem;
}