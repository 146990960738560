@import "quasar.variables";

@mixin to-rem($property, $value) {
  #{$property}: ($value / 16) + rem !important;
}

@mixin set-text-style($size, $line-height, $letter-spacing: 0, $weight: 400) {
  @include to-rem(font-size, $size);
  @include to-rem(line-height, $line-height);
  letter-spacing: $letter-spacing;
  font-weight: $weight;
}

@mixin set-text-color($color) {
  color: $color;
}
