@import "font";
@import "mixin";
@import "color";
@import "quasar.variables";

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

#q-app,
.q-dialog {
  font-family: "SFPro", "Roboto", "-apple-system", sans-serif, Arial;
  white-space: pre-line !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  .max-width {
    max-width: 64rem;
    @at-root body.extra-display & {
      min-width: 37.5rem;
      max-width: calc(var(--vh, 1vh) * 60);
    }
  }

  .default-view-layout {
    // padding: 24px 20px 40px
    padding: 1.5rem 1.25rem 2.5rem;

    @at-root body.responsive-device & {
      padding: 1.5rem 2.5rem 2.5rem;
    }
  }

  .responsive-full-height {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .responsive-at-height {
    min-height: calc(var(--vh, 1vh) * 100 - 6.75rem);
  }

  // training
  // main
  .main-content-container {
    width: 100%;

    .message-layout {
      margin-bottom: 0.5rem;
      border-radius: 1.25rem;
      background-color: white;
      border: 1px solid #ececec;

      @at-root body.responsive-device & {
        margin-bottom: 1rem;
      }

      &.question {
        padding: 0.625rem 1rem;
        @at-root body.responsive-device & {
          padding: 1.125rem 1.875rem;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

.q-dialog {
  &.vertical-dialog .q-card {
    width: 90vw;
    max-width: 25rem;

    @at-root body.responsive-device & {
      width: 87.5vw;
      max-width: 43.75rem;
    }

    @at-root body.extra-display & {
      width: calc(var(--vh, 1vh) * 54);
      max-width: 33.75rem;
    }
  }

  &.horizontal-dialog .q-card {
    width: 80vw;
    max-width: 37rem;

    @at-root body.responsive-device & {
      width: 85vw;
      max-width: 68.75rem;
    }

    @at-root body.extra-display & {
      width: 80vw;
      max-width: 60rem;
    }
  }
}

.chat-text-box {
  width: 100%;
  padding: 0.625rem 1rem;
  border-radius: 1.25rem;
  box-shadow: 0 0 0 1px #ececec;
  outline: none;
  -webkit-appearance: none;
  background: #fff;
  margin-bottom: 0.5rem;
}
