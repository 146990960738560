@import "mixin";

@font-face {
  font-family: "SFPro";
  src: local("SF_Pro_Regular.OTF"),
    url("/css/fonts/SF_Pro_Regular.woff") format("woff"),
    url("/css/fonts/SF_Pro_Regular.woff2") format("woff2"),
    url("/css/fonts/SF_Pro_Regular.ttf") format("ttf");
  font-display: swap;
}

@font-face {
  font-family: "SFProRounded";
  src: local("SF_Pro_Rounded_Regular.OTF"),
    url("/css/fonts/SF_Pro_Rounded_Regular.woff") format("woff"),
    url("/css/fonts/SF_Pro_Rounded_Regular.woff2") format("woff2"),
    url("/css/fonts/SF_Pro_Rounded_Regular.ttf") format("ttf");
  font-display: swap;
}

.SFPro {
  font-family: "SFPro", "Roboto", "-apple-system", sans-serif, Arial;
}

.SFProRounded {
  font-family: "SFProRounded", "Roboto", "-apple-system", sans-serif, Arial;
}

.text-40-b {
  @include set-text-style(40, 60, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(46, 80, 0, 700);
  }
}

.text-32-b {
  @include set-text-style(32, 48, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(42, 65, 0, 700);
  }
}

.text-32 {
  @include set-text-style(32, 48, 0, 400);
  @at-root body.responsive-device & {
    @include set-text-style(40, 60, 0, 400);
  }
}

.text-28-b {
  @include set-text-style(28, 42, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(38, 60, 0, 700);
  }
}

.text-28-b-3 {
  @include set-text-style(28, 36, -3, 700);
  @at-root body.responsive-device & {
    @include set-text-style(38, 60, -3, 700);
  }
}

.text-26-b-3 {
  @include set-text-style(26, 39, -3, 700);
  @at-root body.responsive-device & {
    @include set-text-style(36, 58, -3, 700);
  }
}

.text-26-b {
  @include set-text-style(26, 39, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(36, 58, 0, 700);
  }
}

.text-24-b-3 {
  @include set-text-style(24, 32, -3, 700);
  @at-root body.responsive-device & {
    @include set-text-style(35, 54, -3, 700);
  }
}

.text-24-b {
  @include set-text-style(24, 32, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(35, 54, 0, 700);
  }
}

.text-24-3 {
  @include set-text-style(24, 32, -3, 400);
  @at-root body.responsive-device & {
    @include set-text-style(35, 54, -3, 400);
  }
}

.text-24 {
  @include set-text-style(24, 36, 0, 400);
  @at-root body.responsive-device & {
    @include set-text-style(35, 54, 0, 400);
  }
}

.text-22-b {
  @include set-text-style(22, 33, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(33, 49, 0, 700);
  }
}

.text-22 {
  @include set-text-style(22, 33, 0, 400);
  @at-root body.responsive-device & {
    @include set-text-style(33, 49, 0, 400);
  }
}

.text-20-b-3 {
  @include set-text-style(20, 30, -3, 700);
  @at-root body.responsive-device & {
    @include set-text-style(30, 46, -3, 700);
  }
}

.text-20-b {
  @include set-text-style(20, 30, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(30, 46, 0, 700);
  }
}

.text-20-3 {
  @include set-text-style(20, 24, -3, 400);
  @at-root body.responsive-device & {
    @include set-text-style(30, 46, -3, 400);
  }
}

.text-20 {
  @include set-text-style(20, 30, 0, 400);
  @at-root body.responsive-device & {
    @include set-text-style(30, 46, 0, 400);
  }
}

.text-18-b {
  @include set-text-style(18, 27, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(29, 48, 0, 700);
  }
}

.text-18-b-3 {
  @include set-text-style(18, 27, -3, 700);
  @at-root body.responsive-device & {
    @include set-text-style(29, 48, -3, 700);
  }
}

.text-18 {
  @include set-text-style(18, 27, 0, 400);
  @at-root body.responsive-device & {
    @include set-text-style(29, 48, 0, 400);
  }
}

.text-18-3 {
  @include set-text-style(18, 27, -3, 400);
  @at-root body.responsive-device & {
    @include set-text-style(29, 48, -3, 400);
  }
}

.text-16-b {
  @include set-text-style(16, 27, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(26, 45, 0, 700);
  }
}

.text-16-3 {
  @include set-text-style(16, 27, -3, 400);
  @at-root body.responsive-device & {
    @include set-text-style(26, 45, -3, 400);
  }
}

.text-16 {
  @include set-text-style(16, 27, 0, 400);
  @at-root body.responsive-device & {
    @include set-text-style(26, 45, 0, 400);
  }
}

.text-14-b {
  @include set-text-style(14, 27, 0, 700);
  @at-root body.responsive-device & {
    @include set-text-style(23, 43, 0, 700);
  }
}

.text-14 {
  @include set-text-style(14, 27, 0, 400);
  @at-root body.responsive-device & {
    @include set-text-style(23, 43, 0, 400);
  }
}

.text-14-3 {
  @include set-text-style(14, 27, -3, 400);
  @at-root body.responsive-device & {
    @include set-text-style(23, 43, -3, 400);
  }
}

.text-12 {
  @include set-text-style(12, 24, 0, 400);
  @at-root body.responsive-device & {
    @include set-text-style(22, 40, 0, 400);
  }
}
